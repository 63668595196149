import { ENV_VARS } from '@env';
import { isString, toNumber } from 'lodash-es';

/** @deprecated No longer in use. Use the localDefault option when specifying a flag in list.ts to be generated automatically. */
export const flagDefaultsForLocalDev: Record<string, anyOk> = {
  'app-show-offline-message': false,
  'app-keycloak-min-validity-seconds': 30000,
  'app-keycloak-trigger-refresh-seconds': 240,
  'home-pending-received-page-limit': 12,
  'ME-11055-feat-trailer-id-validation': false,
  'route-lock-polling-interval-ms': isString(ENV_VARS.VITE_POLL_INTERVAL_MS)
    ? toNumber(ENV_VARS.VITE_POLL_INTERVAL_MS)
    : 30_000,
  'route-lock-expiration-warning-modal-seconds': 30,
  'temp-restrict-molo-stop-times': false,
  'accounting-page-limit': 50,
  'offers-board-routes-batch-size': 300,
  'track-page-auto-polling-ms': 0,
  'ME-14627-view-add-offer-with-response': {},
  'ME-11793-app-portfolio-pagination-number': 100,
  'ME-8932-feat-booking-events-subscription': false,
  'ME-18357-feat-route-vendor-details-v2': false,
  'ME-18200-feat-table-context-menu-config': {
    exclude: [],
  } as fixMe,
  'ME-12577-view-autocomplete-address': false,
  'ME-22735-fix-facility-picker-query-limit-number': 200,
  'ME-17877-feat-table-column-resize-exclude': {
    exclude: [
      'main-routes-open',
      'main-routes-open-asl',
      'main-routes-covered-asl',
      'main-routes-dispatched-asl',
      'main-routes-arrived-origin-asl',
      'main-routes-in-transit-asl',
      'main-routes-covered',
      'main-routes-dispatched',
    ],
  } as fixMe,
  'ME-31132-view-main-page-route-board-pagination-size-number': 100,
  'ME-30981-view-track-page-pagination-size-number': 400,
  'ME-31924-view-load-search-pagination-size-number': 400,
  'ME-31432-app-main-page-route-board-polling-ms': 18000,
  'ME-44282-view-record-management-tracking-polling-ms': 18000,
  'ME-54280-app-table-auto-virtualize-above-row-number': 50,
  'ME-35600-feat-masterfind-debounce-ms': 400,
  'ME-23869-feat-Post-Details-Modal-BIN-price': false,
  'ME-35876-feat-booked-by-hyperlink': false,

  // eslint-disable-next-line no-useless-escape
  'ME-32857-app-masterfind-regex': '_x_',
  'ME-31848-feat-remove-stop-event-validation-for-edi-testing': false,
  'ME-56282-feat-customer-crm': false,
  'ME-49695-feat-use-recordsV2': false,
  'ME-56328-feat-open-deck-drawer-fields': false,
  'ME-61696-view-enable-electronic-trackings-table': false,
  'ME-61696-view-enable-carrier-groups-table': false,
  'ME-61696-view-enable-multiselect-employee-groups': false,
  'ME-57583-view-facility-scheduling-size-dropdown-adjustments': false,
  'ME-56363-feat-ROR-decommision': false,
  'ME-61741-view-slack-share': false,
  'ME-61741-view-slack-employee-tree': false,
  'ME-56819-app-fullstory-enable': false,
  'ME-37245-view-allow-multiple-types-on-facility-address': false,
  'ME-61423-view-carrier-general-website-field': false,
  'ME-60304-view-vendor-route-financial-status-reason-codes-vendor-settlement':
    false,
  'ME-69768-view-carrier-record-release-method': false,
  'ME-64246-feat-load-city-state-stop': false,
  'ME-88901-feat-simplified-bookings-kebabs-on-offers-tab': false,
  'ME-53788-feat-refresh-ddt-minionrefreshevent': false,
  'ME-70314-feat-commodity-modal-enhancements': false,
  'ME-62401-feat-use-electronic-trackings': false,
  'ME-41503-feat-shipment-mvp': false,
  'ME-62618-app-invoke-availableRoute-query': false,
  'ME-84078-view-simplified-bookings-route-drawer': false,
  'ME-88898-feat-simplified-bookings-kebabs-on-capacity-manager': false,
  'ME-86013-feat-simplified-bookings-kebabs-on-procure-tab': false,
  'ME-85141-view-commitments-columns-on-route-board-pending-tab': false,
  'ME-85144-view-commitments-columns-on-customer-operations-pending-tab': false,
  'ME-85430-view-hide-front-page-offers': false,
  'ME-85427-view-offers-tab-not-default': false,
  'ME-89159-view-allowable-free-time-UI': false,
  'ME-91969-view-orders-associated-to-lane': false,
  'ME-91969-view-routes-associated-to-lane': false,
  'ME-117923-view-ratequote-and-costquote-service-field': false,
  'ME-50686-view-customer-accounting-bill-to-codes': false,
  'ME-70611-feat-split-shipments': false,
  'ME-31822-feat-add-comma-separated-reference-numbers': false,
  'ME-99230-view-global-vendor-toggle': false,
  'ME-99402-view-saved-filters-pagination-size-number': 50,
  'ME-115982-view-uninvoiced-and-unvouchered-labels': false,
  'ME-165057-view-carrier-customer-relationship-enhancement': false,
  'ME-166225-view-customer-type-on-customer-record': false,
  'ME-185280-generate-alphanumeric-carrier-codes-only': false,
} as const;
